import React, { Component } from 'react';

// import Header from './Header';
import Lady from "../images/fbpost.png";

class Onas extends Component {
    render() {
        return (
            <div>
                {/* <Header /> */}
                <section id="about">
                    <div className="content">
                        <h2>O nas</h2>
                        <div className="section__content">
                            <img src={Lady} alt="Wygląd zewnętrzny firmy" />
                            <div>
                                <p>
                                    <span className="blue">Dental Arts Studio</span> powstało z myślą o wymagających pacjentach.
                                    Osobach, dla których liczy się to, jak świadczy się opiekę stomatologiczną. Bez pośpiechu. Z
                                    uwagą i starannością.
                                    Poznając zwyczaje i preferencje pacjenta. Dopasowując postępowanie terapeutyczne i zakres
                                    diagnostyki do zweryfikowanego zakresu potrzeb.

                                    Przekonasz się jak wiele zyskujesz, gdy decydujesz się na dedykowaną stomatologię.

                                    Już dziś umów się na konsultację.

                                    Z przyjemnością zapraszam na spotkanie.
                                      </p>
                                <h2 className="brown">Stomatologia dedykowana</h2>
                                <p>
                                    <span className="blue">Sztuka stomatologii</span>. Dental Arts Studio wyznacza nową jakość.
                                    Wyobraź sobie płynną rejestrację, pyszną kawę na dzień dobry i personel, który zna Twoje
                                    potrzeby. Możesz liczyć na staranną obsługę w każdym zakresie opieki stomatologicznej.
                                     </p>
                                <p>
                                    Otwieramy w Krakowie nowy gabinet. Styl powszechnie znany za granicą i określany mianem
                                    butikowej stomatologii, jest filozofią dedykowanych usług stomatologicznych.
                                      </p>
                                <br />
                                <p className="brown">
                                    Nasz manifest: stomatologia prestiżowa, dyskretna, unikalnie zorientowana na pacjenta.
                                    Ekskluzywna.
                                       </p>
                            </div>
                        </div>
                        <div className="section__content">
                            <div>
                                <h3 className="blue">Prestiż w standardzie</h3>
                                <p>
                                    <span className="blue">Miejsce dla wymagających</span>. Stawiamy Twoje potrzeby ponad mnożenie
                                    zabiegów i szybkie wizyty.
                                    Dbamy o efektywne wykorzystanie czasu. Nasza poczekalnia może być miejscem dla Ciebie, gdzie,
                                    mając do dyspozycji wiele ciekawych książek i magazynów, przy wybornej kawie, zrelaksujesz się,
                                    słuchając dobrej muzyki.
                                          </p>
                                <br />
                                <p>
                                    <span className="blue">Więcej niż wizyta.</span> Uważnie analizujemy każdy przypadek wymagający
                                    zabiegu. Profilaktykę
                                    traktujemy kompleksowo. Stosujemy zaawansowaną stomatologię prewencyjną.
                                       </p>
                                <br />
                                <p>
                                    <span className="blue">Stomatologia jakości.</span> Precyzja, staranność i wyjątkowa klasa – oto
                                            nasze priorytety. <span className="blue">Eliminujemy</span>
                                    niewygodę, przypadkowość i pospolitość. Zorientowani na Twoje potrzeby, skuteczni w działaniu,
                                            <span className="blue">niezwykle</span> dokładni…
                                        </p>
                                <br />
                                <p>
                                    Nasz Zespół w służbie Twojej satysfakcji.
                                       </p>
                                <br />
                                <p>
                                    <span className="blue">Profesjonalizm podniesiony do potęgi komfortu.</span> Docenisz dyskrecję
                                    i kulturę, która towarzyszy
                                    każdemu spotkaniu. Chcemy, by poczucie wyjątkowości podniosło standard stomatologii.
                                          </p>
                            </div>
                            <img src={Lady} alt="Wygląd zewnętrzny firmy" />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Onas;